import {
	get,
	post,
} from './http.js'

export const userPage = data => {
	return get(`/api/user/page?size=${data.size}&current=${data.current}&uid=${data.uid}&type=${data.type}&tag=${data.tag}`);
}

export const userInfo = data => {
	return get('/api/user/id?uid=' + data);
}

export const userAdd = data => {
	return post('/api/user/add', data);
}

export const userUpdate = data => {
	return post('/api/user/edit', data);
}

export const userDelete = data => {
	return post('/api/user/del?uid=' + data);
}

export const resetUserPwd = data => {
	return post(`/api/user/resetPwd?uid=${data.uid}&password=${data.password}`);
}

export const getCurrentUser = () => {
	return post('/api/user/info');
}