const routes = [{
	path: '/goods/goods',
	name: 'goods',
	meta: {
		title: '商品信息管理'
	},
	component: () => import('@/views/goods/goods.vue'),
}, {
	path: '/goods/recommend',
	name: 'recommend',
	meta: {
		title: '商品推荐管理'
	},
	component: () => import('@/views/goods/recommend.vue'),
}, {
	path: '/goods/discount',
	name: 'discount',
	meta: {
		title: '商品优惠管理'
	},
	component: () => import('@/views/goods/discount.vue'),
}, {
	path: '/goods/medicalExam',
	name: 'medicalExam',
	meta: {
		title: '体检管理'
	},
	component: () => import('@/views/goods/medicalExam.vue'),
},]

export default routes