import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en' //导入element ui中的默认英文配置
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'//导入element ui中的默认中文配置
import zh from './zh'//导入我们在zh.js文件中的词条
import en from './en'//导入我们在en.js文件中的词条
Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
    ...en//把导入的英文词条合并
  },
  zh: {
    ...zhLocale,
    ...zh//把导入的中文词条合并
  }
}
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'zh', // set locale   //注意local 的value是啥就是啥语言 en为英语，zh为中文
  messages, // set locale messages
})
export default i18n  //最后导出i18n