const routes = [{
	path: '/system/user',
	name: 'user',
	meta: {
		title: '用户管理'
	},
	// component: user,
	component: () => import('@/views/system/user.vue'),
}, ]

export default routes