const routes = [{
	path: '/',
	name: 'home',
	meta: {
		title: '主页'
	},
	component: () => import('@/views/home'),
}, {
	path: '/home/banner',
	name: 'banner',
	meta: {
		title: '轮播图管理'
	},
	component: () => import('@/views/home/banner.vue'),
}, {
	path: '/home/module',
	name: 'module',
	meta: {
		title: '模块管理'
	},
	component: () => import('@/views/home/module.vue'),
}, ]

export default routes