<template>
	<div class="root">
		<div v-if="!login">
			<router-view></router-view>
		</div>
		<el-container v-else style="height: 100%;">
			<!-- 侧边栏 -->
			<el-aside :style="menuOpen?'width:200px':'width:0'" width="200px">
				<div class="header-logo">
					蓝莲优医云平台
				</div>
				<el-menu class="aside-menu" :default-active="activeIndex" background-color="#545c64" text-color="#fff"
					active-text-color="#e1e13d" router :collapse="collapse">
					<el-menu-item @click="menuOpen?menuOpen=false:menuOpen=true" class="phone-menu">
						<template slot="title">
							<i class="el-icon-s-fold"></i>
						</template>
					</el-menu-item>
					<el-menu-item index="/">
						<template slot="title">
							<i class="el-icon-data-analysis"></i>
							<span>{{ $t('Dashboard') }}</span>
						</template>
					</el-menu-item>

					<el-submenu v-for="(item,index) in menuList" v-if="item.show&&hasRight(user.rights,item.id)"
						:key="index" :index="index+''">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{item.name}}</span>
						</template>
						<div v-for="(sub,idx) in item.children" v-if="sub.show&&hasRight(user.rights,sub.id)">
							<el-menu-item :index="sub.route">
								{{ $i18n.locale == "zh" ? sub.name : sub.enName }}
							</el-menu-item>
						</div>
					</el-submenu>
				</el-menu>
			</el-aside>
			<!-- 内容区域 -->
			<el-container>
				<el-header class="header">
					<el-breadcrumb style="cursor: pointer;">
						<i @click="menuOpen?menuOpen=false:menuOpen=true" class="el-icon-s-unfold"
							style="font-size: 20px;color: #616161;">
						</i>
					</el-breadcrumb>
					<div class="login-user">
						{{user.nickname}}
						<el-divider direction="vertical"></el-divider>
						<el-dropdown v-if="user">
							<span class="el-dropdown-link">
								<el-avatar :size="35">{{user.nickname?user.nickname.substring(0,1):'未'}}</el-avatar>
							</span>
							<el-dropdown-menu slot="dropdown">
								<template>
									<el-dropdown-item>
										<template>
											<el-button type="text" @click="logout">退出登录</el-button>
										</template>
									</el-dropdown-item>
								</template>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-header>
				<el-main style="overflow: scroll;">
					<!-- 路由视图 -->
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import {
		getCurrentUser,
	} from '@/http/user'
	import {
		logout,
	} from '@/http/base'
	import {
		menuList,
	} from '@/router/menu.js'

	export default {
		data() {
			return {
				menuOpen: true,
				baseurl: '',
				EnglishName: '',
				menuList: menuList,
				login: false,
				activeIndex: '0',
				collapse: false,
				breadcrumbTwo: '',
				breadcrumbThree: '',
				breadcrumbShow: false,
				user: {
					nickname: '未登录',
				},
			}
		},
		methods: {
			logout() {
				this.$confirm('确定要退出当前账号吗?', '提示').then(() => {
					logout(null).then(res => {
						this.$message({
							type: 'success',
							message: res.msg
						});
						localStorage.removeItem('token');
						this.$router.push({
							path: '/login'
						});
						window.location.reload()
					})
				}).catch(() => {});
			},
			changLang() {
				this.$i18n.locale =
					this.$i18n.locale == "zh" ?
					(this.$i18n.locale = "en") :
					(this.$i18n.locale = "zh");
			},
			getCurrentUser() {
				this.user = {};
				getCurrentUser().then(res => {
					if (res.code == 200) {
						this.user = res.data;
					}
				})
			},
			hasRight(rights, key) {
				if (!rights) return false;
				let list = rights.split(',');
				for (let item of list) {
					if (item == key) {
						return true;
					}
				}
				return false;
			},
		},
		watch: {
			$route(to, from) {
				if (to.fullPath == '/' && from.fullPath == '/login') {
					window.location.reload()
				}
			}
		},
		created() {
			if (localStorage.getItem('token')) {
				this.login = true;
				this.getCurrentUser();
			}
		},
	}
</script>

<style lang="less" scoped>
	::-webkit-scrollbar {
		width: 0;
		/* 隐藏滚动条宽度 */
	}

	.root {
		height: 100vh;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e8e8e8;
		background-color: #fff;
		text-align: center;
	}

	.el-aside {
		display: flex;
		height: 100%;
		flex-direction: column;
	}

	.aside-menu {
		flex: 1;
		overflow-y: scroll;
	}

	.header-logo {
		width: 200px;
		color: white;
		font-size: 20px;
		font-weight: 900;
		height: 60px;
		line-height: 60px;
		text-align: center;
		background-color: #4a5158;

		.header-logo-img {
			height: 60px;
			width: 60px;
		}
	}

	.login-user {
		color: #737373;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.lang {
		cursor: pointer;
		text-align: center;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border: 1px solid gray;
		margin-right: 20px;
	}

	.el-container .el-aside {
		transition: 0.3s;
		z-index: 999;
	}

	.phone-menu {
		display: none;
	}
</style>