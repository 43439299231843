export default {
	/*-----------------------------菜單類(開始)-----------------------------*/
	// 儀錶盤
	Dashboard: '首页',
	/*-----------------------------菜單類(結束)-----------------------------*/
	
	/*-----------------------------操作按鈕類(開始)-----------------------------*/
	Search: '查询',
	Send: '发送',
	Reset: '重置',
	Add: '添加',
	Delete: '删除',
	Edit: '编辑',
	Refresh: '刷新',
	New: '新增',
	Export: '导出',
	Print: '打印',
	Detail:'明细',
	EditPrjCategory:'分類管理',
	AttendSetting: '考勤設置',
	/*-----------------------------操作按鈕類(結束)-----------------------------*/
}