<template>
	<div class="login-box">
		<div class="login-body">
			<h1>蓝莲优医云平台</h1>
			<input type="text" class="username" v-model="user.username" placeholder="账号" @keypress="handleKeyPress" />
			<input type="password" class="password" v-model="user.password" placeholder="密码"
				@keypress="handleKeyPress" />
			<button @click="login"><span>登录</span></button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				user: {
					username: "",
					password: "",
				},
			};
		},
		methods: {
			handleKeyPress(e) {
				if (e.keyCode === 13) {
					// 执行回车键被按下后的逻辑
					this.login();
				}
			},
			login() {
				const data = {
					username: this.user.username,
					password: this.user.password
				}
				this.$post('/api/login', data).then(res => {
					if (res.code == 200) {
						localStorage.setItem('token', res.data.token);
						this.$router.push({
							path: '/'
						});
					}
				})
			},
		},
	};
</script>

<style scoped>
	.login-box {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #fff;
	}

	.login-body {
		padding: 50px;
		border-radius: 15px;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
	}

	h1 {
		font-size: 36px;
		color: #00c0a6;
		margin-bottom: 10px;
	}

	input,
	button {
		display: block;
		width: 400px;
		height: 45px;
		border: 1px solid #d8d8d8;
		margin: 28px auto;
		border-radius: 22.5px;
	}

	input {
		padding-left: 50px;
		text-align: left;
		font-size: 16px;
		color: grey;
	}

	button {
		border: none;
		background-color: #00c0a6;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		margin-top: 30px;
		cursor: pointer;
	}

	button:hover {
		background-color: #00d8b8;
	}

	input:focus {
		outline: 1px solid #efefef;
	}
</style>