export default {
	/*-----------------------------菜單類(開始)-----------------------------*/
	// 儀錶盤
	Dashboard: 'Dashboard',
	/*-----------------------------菜單類(結束)-----------------------------*/
	
	/*-----------------------------操作按鈕類(開始)-----------------------------*/
	Search: 'Search',
	Send: 'Send',
	Reset: 'Reset',
	Add: 'Add',
	Delete: 'Detele',
	Edit: 'Edit',
	Refresh: 'Refresh',
	New: 'New',
	Export: 'Export',
	Print: 'Print',
	Detail:'Detail',
	EditPrjCategory:'Project Category',
	AttendSetting: 'Attendance Setting',
	/*-----------------------------操作按鈕類(結束)-----------------------------*/
}