import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/router/home'
import system from '@/router/system'
import login from '@/router/login'
import goods from '@/router/goods'
import order from '@/router/order'

Vue.use(VueRouter)

const routes = [
	...home,
	...system,
	...login,
	...goods,
	...order,
	// 404永远放在最后面，否则所有页面都会404
	{
		path: '/*',
		name: '404',
		meta: {
			title: '404'
		},
		component: () => import('@/views/404.vue'),
	},
]
const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router